import verConfig, { ver as _ver_ } from "@tt/config";
export const ver = _ver_;

export const verData = {
  "A6420": {
    tabbar: {
      background: "#fff",
      selectedColor: "#0b93ae",
      color: "#fff",
      type: "image",
      pages: [{
        text: 'newTab.home',
        pagePath: 'goods',
        component: () => import("@/view/goods"),
        iconPath: require("@/assets/tabbar/home.png"),
        selectedIconPath: require("@/assets/tabbar/home_active.png"),
        isSpecial: false,
      }, {
        text: `${'newTab.bettingRecord'}`,
        pagePath: 'bettingRecord',
        component: () => import('@/view/bettingRecord'),
        iconPath: require("@/assets/tabbar/activity.png"),
        selectedIconPath: require("@/assets/tabbar/activity.png"),
        isSpecial: false,
      }, {
        text: `${'newTab.games'}`,
        pagePath: 'Merchants',
        component: () => import('@/view/new/merchants'),
        iconPath: require("@/assets/tabbar/datin.svg"),
        selectedIconPath: require("@/assets/tabbar/datin.svg"),
      }, {
        text: `newTab.customerService`,
        pagePath: 'activity',
        component: () => import('@/view/new/activity'),
        iconPath: require("@/assets/tabbar/cs.svg"),
        selectedIconPath: require("@/assets/tabbar/cs.svg"),
        isSpecial: false,
      }, {
        text: `${'newTab.my'}`,
        pagePath: 'user',
        component: () => import('@/view/user'),
        iconPath: require("@/assets/tabbar/mine.png"),
        selectedIconPath: require("@/assets/tabbar/mine_active.png"),
        isSpecial: false,
      }]
    }
  },
}

export default {
  ...verConfig, ...verData[ver]
};