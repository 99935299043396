import { $post, $get, dealError } from "@/utils";
import store from "./index"
let loading = false;
let reqTimes = 0;
const game = {
  state: {
    allGameList: [],
    hotGameList: [],
    gameType: []
  },
  getters: {
    AllGameList(state) {
      if (!state.allGameList.length) {
        store.dispatch("getGameList", { type: "all" });
      }
      return state.allGameList;
    },
    HotGameList(state) {
      if (!state.hotGameList.length) {
        store.dispatch("getGameList", { type: "hot" });
      }
      return state.hotGameList;
    },
    GameType(state) {
      if (!state.gameType.length) {
        store.dispatch("getGameType");
      }
      return state.gameType;
    },
  },
  mutations: {
    SET_GAMELIST: (state, { type, data }) => {
      switch (type) {
        case "all":
          state.allGameList = data || [];
          break;
        case "hot":
          state.hotGameList = data || [];
          break;
        default:
          break;
      }
    },
    SET_GAMETYPE(state, { data }) {
      state.gameType = data || []
    },
  },
  actions: {
    getGameList({ commit }, payload = { type: "all" }) {
      return new Promise(async (resolve, reject) => {
        try {
          const { type } = payload;
          const { ret, msg, data } = await $get("/lottery/setting", {
            merchant_type: payload.type,
            lastinfo: 1,
          })
          commit("SET_GAMELIST", { type, data });
          return resolve({ ret, data, msg });
        } catch (error) {
          dealError(error);
          return reject(error);
        }
      })
    },
    getGameType({ commit }) {
      if (loading) return;
      loading = true;
      return new Promise(async (resolve, reject) => {
        try {
          const { ret, msg, data } = await $get('/lottery/category');
          commit("SET_GAMETYPE", { data });
          loading = false;
          return resolve({ ret, data, msg });
        } catch (error) {
          loading = false;
          dealError(error);
          return reject(error);
        }
      })
    }
  },
}
export default game
