import { $post, $get, dealError } from "@/utils";
import store from "./index"
let loading = false;
const user = {
  state: {
    loginInfo: {},
    moneyObj: {},
    f5lodiing: false,
    userInfo: {}
  },
  mutations: {
    SET_USERINFO(state, data) {
      state.userInfo = { ...state.userInfo, ...data };
    },
    SET_LOGIN_INFO: (state, loginInfo) => {
      state.loginInfo = loginInfo
    },
    SET_MONEY_INFO: (state, moneyObj) => {
      state.moneyObj = moneyObj
    },
    SET_f5LODIING: (state, f5lodiing) => {
      state.f5lodiing = f5lodiing
    },
  },
  getters: {
    userInfo(state) {
      const token = state.userInfo.token;
      if (!token) {
        store.dispatch("getUserInfo")
      }
      return state.userInfo;
    },
  },
  actions: {
    login({ commit }, res) {
      commit('SET_LOGIN_INFO', res.loginInfo)
    },
    moneyInfo({ commit }, res) {
      commit('SET_MONEY_INFO', res.moneyObj)
    },
    pageLodiing({ commit }, res) {
      commit('SET_f5LODIING', res.f5lodiing)
    },
    getUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        if (loading) return;
        loading = true;
        $get("/users/info").then(({ data }) => {
          loading = false;
          commit("SET_USERINFO", { ...data })
        }).catch(err => {
          loading = false;
          dealError(err)
        })
      })
    },
  },
}
export default user
